import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Typography } from "@mui/material"

export const Wrapper = styled(SectionWrapper)``

export const Title = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(26)};
  line-height: ${({ theme }) => theme.typography.pxToRem(32)};
  text-align: center;
  letter-spacing: -0.015em;
  color: ${({ theme }) => theme.palette.primary.main};
  text-transform: uppercase;
  margin-bottom: 0.5rem;

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(32)};
    line-height: ${({ theme }) => theme.typography.pxToRem(46)};
  }

  ${({ theme }) => theme.breakpoints.up("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(44)};
    line-height: ${({ theme }) => theme.typography.pxToRem(66)};
  }
`

export const Text = styled(Typography)`
  font-weight: 600;
  font-size: ${({ theme }) => theme.typography.pxToRem(18)};
  line-height: ${({ theme }) => theme.typography.pxToRem(27)};
  text-align: center;
  text-transform: uppercase;
  color: #659079;
  p {
    margin: 0;
  }
`

export const TopWrapper = styled.div`
  margin-bottom: 2rem;

  ${({ theme }) => theme.breakpoints.up("md")} {
    margin-bottom: 4rem;
  } ;
`
