import React, { useEffect } from "react"
import * as S from "./shop-section.styles"
import { isBrowser } from "../../utils/utils"

const ShopSection = ({}) => {
  const getParams = url => {
    let params = {}
    let parser = document.createElement("a")
    parser.href = url
    let query = parser.search.substring(1)
    let vars = query.split("&")
    params["path"] = ""
    for (let i = 0; i < vars.length; i++) {
      console.log(vars[i])
      vars[i] = vars[i].replace("%3D", "=")
      vars[i] = vars[i].replace("%3F", "?")
      vars[i] = vars[i].replace("%2F", "/").replace("%2F", "/")
      if (
        vars[i].includes("path=") ||
        vars[i].includes("refinementList") ||
        vars[i].includes("query")
      ) {
        console.log(params["path"])
        let pair = vars[i].split("=")
        if (pair.length == 3) {
          params["path"] += pair[0] + "=" + pair[1] + "=" + pair[2]
        } else if (pair.length == 2) {
          if (!vars[i].includes("path=")) {
            params["path"] += pair[0] + "=" + pair[1]
          } else {
            params["path"] += pair[1]
          }
        } else {
          params["path"] += pair[1]
        }
        params["path"] += "&"
      }
    }
    return params
  }

  useEffect(() => {
    const baseURL = "https://www.iheartjane.com/embed/stores/3432/"
    if (isBrowser) {
      const params = getParams(window.location.href)
      if (params["path"]) {
        const newSrc = baseURL + params["path"]
        document.getElementById("jane-menu").src = newSrc
      }
      document.getElementById("root").style.minHeight = 0
    }
  }, [])

  return (
    <S.Wrapper>
      <iframe
        title="Shop"
        src="https://api.iheartjane.com/v1/stores/3432/embed.js"
        style={{ width: "100vw", height: "100vh" }}
        frameBorder="0"
        marginHeight="50px"
        marginWidth="20px"
      >
        Loading…
      </iframe>
    </S.Wrapper>
  )
}

export default ShopSection
