import styled from "@emotion/styled"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"

export const Wrapper = styled(SectionWrapper)`
  background-color: white;

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .swiper {
      padding: 1em 7em 1em 0;
      overflow: visible;
    }
  }
`

export const Title = styled.h2`
  font-weight: 500;
  font-size: ${({ theme }) => theme.typography.pxToRem(42)};
  line-height: 46px;
  margin-bottom: ${({ theme }) => theme.typography.pxToRem(60)};
  color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(32)};
    line-height: 48px;
    margin-bottom: 2rem;
  }
`
