import styled from "@emotion/styled"
import { Typography } from "@mui/material"
import CustomLink from "../custom-link/custom-link.component"
import CustomButton from "../custom-button/custom-button.component"
import CustomImage from "../custom-image/custom-image.component"

export const CardWrapper = styled.div`
  padding: ${({ theme }) => theme.typography.pxToRem(52)}
    ${({ theme }) => theme.typography.pxToRem(40)};
  background-color: rgba(177, 218, 169, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 0.5px solid #b1daa9;
  border-radius: 20px;
  height: 100%;

  &.imagePadding {
    padding: ${({ theme }) => theme.typography.pxToRem(10)}
      ${({ theme }) => theme.typography.pxToRem(24)}
      ${({ theme }) => theme.typography.pxToRem(28)};
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 2rem;
`

export const Title = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: ${({ theme }) => theme.typography.pxToRem(30)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.palette.primary.main};

  ${({ theme }) => theme.breakpoints.up("sm")} {
    font-size: 1.5rem;
    line-height: ${({ theme }) => theme.typography.pxToRem(36)};
  }
`

export const Content = styled.div`
  p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    font-size: ${({ theme }) => theme.typography.pxToRem(16)};
    line-height: 1.25rem;
    color: ${({ theme }) => theme.palette.text.link};

    ${({ theme }) => theme.breakpoints.up("md")} {
      font-size: ${({ theme }) => theme.typography.pxToRem(17)};
      line-height: 1.5rem;
    }
  }
`

export const Link = styled(CustomLink)`
  text-decoration: underline;
  color: ${({ theme }) => theme.palette.text.link};
`

export const Button = styled(CustomButton)`
  align-self: flex-start;
  margin-top: 1.25rem;
`

export const Image = styled(CustomImage)``
