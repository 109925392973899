import React from "react"
import MiscContent from "../layouts/MiscContent"
import InstagramSection from "../layouts/InstagramSection"
import InfoRow from "../layouts/InfoRow"
import HeroSlider from "../layouts/HeroSlider"
import CategoryProducts from "../layouts/CategoryProducts"
import OurApp from "../layouts/OurApp"
import PostsItems from "../layouts/PostsItems"
import EmbeddedForm from "../layouts/EmbeddedForm/embedded-form.component"
import HiringSection from "../layouts/HiringSection/hiring-section.component"
import VipSection from "../layouts/VipSection/vip-section.component"
import ShopSection from "../layouts/ShopSection/shop-section.component"
import SpecialOffers from "../layouts/SpecialOffers"
import HomeSpecials from "../layouts/HomeSpecials"
import HeroCards from "../layouts/HeroCards/hero-cards.component"

export const getPageLayout = layout => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "InstagramSection":
      return <InstagramSection {...layout} />
    case "InfoRow":
      return <InfoRow {...layout} />
    case "HeroSlider":
      return <HeroSlider {...layout} />
    case "CategoryProducts":
      return <CategoryProducts {...layout} />
    case "OurApp":
      return <OurApp {...layout} />
    case "PostsItems":
      return <PostsItems {...layout} />
    case "EmbeddedForm":
      return <EmbeddedForm {...layout} />
    case "HiringSection":
      return <HiringSection {...layout} />
    case "VipSection":
      return <VipSection {...layout} />
    case "ShopSection":
      return <ShopSection {...layout} />
    case "SpecialOffers":
      return <SpecialOffers {...layout} />
    case "HomeSpecials":
      return <HomeSpecials {...layout} />
    case "HeroCards":
      return <HeroCards {...layout} />
  }
}
