import React from "react"
import * as S from "./hero-cards.styles"
import { Container, Grid } from "@mui/material"
import HeroCard from "../../components/hero-card/hero-card.component"
import parse from "html-react-parser"

const HeroCards = ({ title, text, cards }) => {
  if (!cards) return null
  return (
    <S.Wrapper>
      <Container maxWidth="xl">
        <S.TopWrapper>
          <S.Title>{title}</S.Title>
          <S.Text>{parse(text)}</S.Text>
        </S.TopWrapper>
        <Grid container spacing={3} justifyContent="center">
          {cards.map((card, index) => (
            <Grid item key={`hero-card-${index}`} md={4} sm={6} xs={12}>
              <HeroCard {...card} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </S.Wrapper>
  )
}

export default HeroCards
