import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { getPageLayout } from "../../utils/get-layout-utils"

export const query = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      isFrontPage
      title
      content
      uri
      slug
      parentId
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_InstagramSection {
            ...InstagramSection
          }
          ... on WpPage_Pagebuilder_Layouts_InfoRow {
            ...InfoRow
          }
          ... on WpPage_Pagebuilder_Layouts_HeroSlider {
            ...HeroSlider
          }
          ... on WpPage_Pagebuilder_Layouts_CategoryProducts {
            ...CategoryProducts
          }
          ... on WpPage_Pagebuilder_Layouts_OurApp {
            ...OurApp
          }
          ... on WpPage_Pagebuilder_Layouts_PostsItems {
            ...PostsItems
          }
          ... on WpPage_Pagebuilder_Layouts_EmbeddedForm {
            ...EmbeddedForm
          }
          ... on WpPage_Pagebuilder_Layouts_VipSection {
            ...VipSection
          }
          ... on WpPage_Pagebuilder_Layouts_HiringSection {
            ...HiringSection
          }
          ... on WpPage_Pagebuilder_Layouts_ShopSection {
            ...ShopSection
          }
          ... on WpPage_Pagebuilder_Layouts_SpecialOffers {
            ...SpecialOffers
          }
          ... on WpPage_Pagebuilder_Layouts_HomeSpecials {
            ...HomeSpecials
          }
          ... on WpPage_Pagebuilder_Layouts_HeroCards {
            ...HeroCards
          }
        }
        pageConfiguration {
          hideFooter
          hideHeaderItems
        }
      }
    }
  }
`
const PageTemplate = ({ data }) => {
  const { seo, slug, pageBuilder, title } = data.wpPage
  const layouts = pageBuilder.layouts || []
  return (
    <Layout {...pageBuilder.pageConfiguration} seo={seo}>
      {layouts.map(layout => getPageLayout(layout))}
    </Layout>
  )
}

export default PageTemplate
