import React from "react"
import * as S from "./hero-card.styles"
import parse from "html-react-parser"
import CustomButton from "../custom-button/custom-button.component"

const HeroCard = ({ title, content, url, linkButton, image }) => {
  return (
    <S.CardWrapper className={image ? "imagePadding" : ""}>
      {image && <S.Image img={image} />}
      <S.ContentWrapper>
        {title && <S.Title>{parse(title)}</S.Title>}
        {content && <S.Content>{parse(content)}</S.Content>}
      </S.ContentWrapper>
      {url && <S.Link url={url.url}>{url.title}</S.Link>}
      {linkButton && (
        <S.Button className="transparent" href={linkButton?.url}>
          {linkButton?.title}
        </S.Button>
      )}
    </S.CardWrapper>
  )
}

export default HeroCard
