import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Grid, useMediaQuery, useTheme } from "@mui/material"
import NewsEventCard from "../../components/news-event-card/news-event-card.component"

import * as S from "./posts-items.styles.jsx"

const PostsItems = ({ title, posts }) => {
  const theme = useTheme()
  const isSM = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <S.Wrapper contained>
      {title && <S.Title>{title}</S.Title>}
      {!isSM ? (
        <Grid container spacing={2.25}>
          {posts.map(({ title, description, link, img }, index) => (
            <Grid item xs={12} sm={6} md={4} key={`product-${index}`}>
              <NewsEventCard
                title={title}
                description={description}
                button={{ url: link?.url, title: link?.title }}
                image={img}
                removeBorder={true}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Swiper
          breakpoints={{
            300: {
              slidesPerView: 1,
            },
            450: {
              slidesPerView: 2,
            },
          }}
          spaceBetween={20}
          loop={true}
        >
          {posts.map(({ title, description, link, img }, index) => (
            <SwiperSlide key={`post-item-${index}`}>
              <NewsEventCard
                title={title}
                description={description}
                button={{ url: link?.url, title: link?.title }}
                image={img}
                removeBorder={true}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </S.Wrapper>
  )
}
export default PostsItems
